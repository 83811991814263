import browserUpdate from 'browser-update';

browserUpdate({
    required: {
		e:-3,
		f:-3,
		o:-3,
		s:-1,
		c:-3,
        samsung: 7.0,
        vivaldi: 1.2
    },
	insecure: true,
	unsupported: true,
	nostatistics: true,
	reminder: 24,
	reminderClosed: 24,
	newwindow: true,
	style: 'top',
	no_permanent_hide: true,
	text: {
		'msg':'Your web browser ({brow_name}) is out of date.',
		'msgmore': 'Some parts of this site may not display correctly, update your browser to receive the best experience',
		'bupdate': 'Update browser',
		'bignore': 'Ignore',
		'remind': 'You will be reminded in {days} days.',
		'bnever': 'Never show again'
	}
})

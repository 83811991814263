import $ from 'jquery';

// Enable body scrolling
export function enableScroll() {
	const scrollTop = parseInt($('html').css('top'));
	$('html').removeClass('u-disable-scrolling');
	$('html, body').scrollTop(-scrollTop);
}

// Disable body scrolling, maintaining position and keeping scrollbar
export function disableScroll() {
	if($(document).height() > $(window).height()) {
		const scrollTop = $('html').scrollTop() || $('body').scrollTop();
		$('html').addClass('u-disable-scrolling').css('top', -scrollTop);
	}
}

import $ from 'jquery';

import 'jquery-ui-bundle';

$(() => {
	// Set jqueryui accordion parameters
    let $accordions = $('.js-accordion').accordion({
        collapsible: true,
	  	active: false,
		autoheight: true,
		duration: 500
    }).on('click', () => {
		// Assign clicked element to $this
		const $this = event.currentTarget;

		window.setTimeout( () => {

			// Define small device query
			let isSmallMQ = window.matchMedia('(max-width: 575px)');

			// Default offset
			let offset = 140

			// Larger offset for small device due to larger header
			if ( isSmallMQ.matches ){
				offset = 200
			}

			// Scroll to clicked element position with offset
			const elementRect = $this.getBoundingClientRect();
			const absoluteElementTop = elementRect.top + window.pageYOffset - offset;
			window.scrollTo({ left: 0, top: absoluteElementTop, behavior: 'smooth' });
		}, 500 );
		// Close accordions apart from clicked
		$accordions.not($this).accordion({ active: false });
    });
});

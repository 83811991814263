import $ from 'jquery';
import Flickity from 'flickity';

$(() => {

	if( !$('#js-gallery-thumbnails').length ) {
		return;
	}

	const flickity = new Flickity('#js-gallery-thumbnails', {
		// options
		arrowShape: 'M21.988 43l-.3.41c-.11.15-.23.3-.34.46-.11.16-.2.32-.29.49-.09.17-.18.28-.26.43-.08.15-.16.34-.24.51-.08.17-.15.31-.22.47-.07.16-.12.33-.18.5a5.17 5.17 0 00-.18.51 4.77 4.77 0 00-.13.51c0 .18-.1.35-.14.54l-.09.59c0 .15-.05.31-.06.47a9.84 9.84 0 000 2.17 3.85 3.85 0 00.07.49c0 .2.05.39.08.57.03.18.09.38.14.56l.12.49.19.54c.06.15.11.32.18.47.07.15.15.33.23.5a5.24 5.24 0 00.23.48c.08.16.18.31.27.46.09.15.18.31.28.46.1.15.25.34.37.5l.27.37c.25.3.51.58.79.86l39 39a11.01 11.01 0 0015.55-15.59L46.608 50l30.66-31.22a11.017 11.017 0 00-15.6-15.56l-38.95 39a10.44 10.44 0 00-.73.78z',
		prevNextButtons: true,
		pageDots: false,
		cellAlign: 'left',
		contain: true
	});

	// Set main gallery image on thumbnail click
	flickity.on('staticClick', (event, pointer, cellElement, cellIndex) => {
		// Get full-size image from href on thumbnail wrapper
		const selectedImage = $(cellElement).attr('href');

		$('#js-gallery-image img').attr('src', selectedImage);
	});
});
